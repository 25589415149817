import '../styles/LearnMoreSection.css'

const LearnMoreSection = () => {
  return (
    <div className="learn-more-container">
        <div className="column">
          <h2>PROMOTIONS. PROFESSIONALISM. PARTNER.</h2>
          <p>
            DR Promotions, is your premier partner for beer, wine, and spirit promotions in South Carolina, North Carolina, and Virginia. With a passion for excellence and an commitment to innovation, DR Promotions specializes in creating opporunities to increase brand awareness. Our expertise spans across crafting unique promotional strategies, digitial content, organizing high-impact events, and fostering meaningful connections between brands and their target audiences.
          </p>
        </div>
        <div className="column">
          <h2>ON TOP OF THE ROCK.</h2>
          <p>
            At DR Promotions, we understand that the essence of a great product lies not just in its taste, but in the stories it tells and the experiences it creates. Our dedicated team of professionals is adept at delivering events that resonate and leave a lasting impression. Whether you are launching a new product, revitalizing an existing brand, or looking to expand your reach, DR Promotions is here to ensure your success every step of the way.
          </p>
        </div>
      </div>

  );
};

export default LearnMoreSection;
